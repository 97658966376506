//Override the default bootstrap theme
@import "../../node_modules/bootstrap/scss/functions";


@import "./overrides";

@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";

@import "../../node_modules/bootstrap/scss/utilities";

//Utilities Merge
@import "./utilitiy_merges";

// Layout & components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/accordion";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/offcanvas";
@import "../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";


//font
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300&display=swap");

// Bootstrap Icons
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

// Swiper
@import "../../node_modules/swiper/swiper-bundle.min.css";

@import "../../node_modules/glightbox/dist/css/glightbox.css";


@import "./header";
@import "./Toggle-Button";
@import "./footer";
@import "./widgets.scss";
@import "./car-reviews.scss";

.btn-tag {
    @include button-variant($gray-200, $gray-200, $blue-300, $blue-300, $blue-300, $white, $blue-300, $blue-300, $white);
}

/// body-font-size/family
body {
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
}

/// override a tex-decoration
a {
    text-decoration: none;
}

/// google adds general style
.google-ads {
    display: flex;
    justify-content: center;
    // padding: 1.75rem 0.85rem 0;
}

/// genral widget for news style
.widget {

    // general bold header
    .widget-header {
        .title {
            color: $primary;
            padding: 1.4rem 0rem;
            margin-bottom: 0;
        }
    }

    // review overlap title
    .reviews {
        background-color: $gray-700;
        padding: 3rem;
        color: $white;

        &__title {
            color: $white;
            line-height: 1.14;
        }

        &__tag {
            color: $gray-500;
        }

        &__respoive {
            pointer-events: none;
            position: absolute;
            top: 0;
            right: -1rem;
            left: -1rem;
            bottom: 0;
            background-color: $gray-700;
            z-index: -1;
        }
    }

    // widget body - img/text/tag
    .widget-body {
        position: relative;

        &__img {
            position: relative;
            overflow: hidden;

            img {
                transition: all .5s;

                &:hover {
                    transform: scale(1.09);
                }
            }
        }

        // purple badge
        &__badge {
            background-color: $pink-700;
            height: 1.812rem;
            position: absolute;
            color: $white;
            top: 0rem;
            font-size: 0.75rem;
            padding: 0.5rem;
        }

        // widget text
        &__text {
            .news-title {
                color: $dark;
                line-height: 1.14;
            }

            p {
                line-height: 1.44;
                margin-bottom: 0.5rem;
            }

            &--Category {
                background-color: $pink-700;
                color: $white;
                font-size: 0.75rem;
                padding: 0.5rem;
                width: 9rem;
                margin-bottom: 0.5rem;
            }
        }

        // widget footer - tag and published date
        &__footer {
            font-size: 0.75rem;
            position: relative;

            &--tag {
                color: $blue-300;
                padding-right: 0.625rem;
            }

            &--date {
                color: $gray-500;
                padding-left: 0.625rem;
                position: relative;

                &::before {
                    position: absolute;
                    border-left: 1px solid $gray-500;
                    content: "";
                    bottom: 10%;
                    left: 0;
                    right: 0;
                    top: 10%;
                }
            }
        }

    }

    /// widget footer - button 
    .widget-footer {
        border-bottom: 1px solid $gray-200;
        padding: 1.5rem 0;

    }

    // set border-bottom for widget
    &:last-child {
        .widget-footer {
            border-bottom: none;
        }
    }
}

/// forms style
.form-group,
.input-group,
.form-select,
.form-check {
    margin-bottom: 1rem;
}

/// most-read / related news style
.most-read {
    background-color: $light;

    &__title {
        font-size: 1.125rem;
        color: $gray-800;
        line-height: 1.39;
        margin-bottom: 0;
        padding: 1rem;
    }

}


// change font-size for small tags  
.tag-font-small {
    span {
        font-size: 0.625rem;
    }

}


/// zoom hover for images
.img-zoom {
    position: relative;
    overflow: hidden;

    img {
        transition: all .5s;

        &:hover {
            transform: scale(1.09);
        }
    }

}

/// style for overlap text - white
.overlay-heading-white {
    margin-top: -2rem;
    position: relative;
    width: calc(100% - 4rem);
    padding-top: 1rem;
    background-color: $white;
    margin-left: -1px;
    border-width: 0;
}

/// style for overlap text - dark
.overlay-heading-dark {
    width: calc(100% - 3rem);
    background-color: $gray-700;
    margin-top: -3rem;
    padding: 1.5rem 0.625rem 0.5rem 0;
    position: relative;
}

// changing hr style color 
hr {
    border-bottom: 1px solid $gray-200;
}

/// line clamp sm - 2
.line-clamp-sm {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/// line clamp lg - 2
.line-clamp-lg {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


.gray {
    color: $gray-500 !important;
}

/// change ratio on md down
.ratio {
    &.ratio-16x9 {
        @include media-breakpoint-down(lg) {
            --bs-aspect-ratio: calc(3 / 4 * 100%);
        }
    }

    &>img {
        object-fit: cover;
    }
}


/// issue  title border bottom
.small-border {
    position: relative;

    &::after {
        position: absolute;
        border-bottom: 3px solid $primary;
        content: "";
        bottom: 10%;
        left: 0;
        right: 0;
        top: 100%;
        width: 2.5rem;
        height: 1rem;
    }
}

/// article tag border
.small-right-border {
    overflow: hidden;
    margin: 0 -1rem;

    li {
        &::before {
            content: "";
            background-color: $gray-300;
            position: absolute;
            height: 12px;
            width: 1px;
            left: -1px;
            transform: translateY(25%);
        }

    }
}

.fc-gray-300 {
    color: $gray-300;
}

/// autor body cotent 
.italic-p {
    font-style: italic;
    line-height: 1.44;
    color: $gray-600;
}

/// p body line-height 
.lh-responsive {

    line-height: 1.44;
}

/// form-control style
.comment-part {
    .form-control {
        border-radius: 0;
        border: 1px solid $gray-200;
        color: $gray-400;
        padding: 1rem;
        font-size: 0.875rem;

        /// changing the placeholder color
        &::placeholder {
            color: $gray-400;
        }
    }
}

/// article header, content, quote, buttons
.article-header {
    border-bottom: 1px solid $gray-200;
    position: relative;

    &__content {
        margin: 1rem 0;
        max-width: 36.25rem;
    }

    &__quote {
        color: $gray-300;
        line-height: 1.33;
        font-size: 1.875rem;

    }

    &__buttons {
        padding-bottom: 1.5rem;

        .nav-link:first-child {
            padding-left: 0;
        }

        .nav-pills .nav-link {
            background: $gray-200;
            font-weight: normal;

        }

        .nav-item {
            padding-right: 1rem;
        }

    }

    &__img {
        position: relative;
        overflow: hidden;

        img {
            transition: all .5s;

            &:hover {
                transform: scale(1.09);
            }


        }

        &--caption {
            content: "";
            width: 4.5rem;
            height: 2.8rem;
            text-align: center;
            background-color: $black;
            color: $white;
            position: absolute;
            top: 1.2rem;
            bottom: 0;
            left: 1.25rem;
            border-radius: 0.375rem;
            opacity: 0.75;
            z-index: 10;

            &:hover {
                color: $white;
            }
        }
    }

    .swiper-button-next,
    .swiper-button-prev {

        color: $white;
    }



}

.article-nav {

    .nav-pills {
        .nav-item {
            padding-right: 1rem;

            .nav-link {
                font-weight: normal;

                &:not(.active) {
                    background: $gray-200;


                }
            }
        }
    }
}





/// article body - style for "More article"
.article-body {
    &__more {
        a {
            padding: 1.5rem 0;
            border-bottom: 1px solid $gray-200;
            font-size: 1rem;
        }
    }

    /// Info at a glance part
    &__info {
        h3 {
            border-bottom: 2px solid $blue-300;
            padding-bottom: 1rem
        }



        .list-group-flush>.list-group-item {
            padding: 1.25rem 0;
        }

        .list-group-flush>.list-group-item:last-child {
            border-bottom-width: 1;
        }
    }

    ///
    .table> :not(caption)>*>* {
        padding: 1.25rem;
    }

    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
        border: 0;
    }
}


/// bbc content-width
.content-width {
    margin: 1rem 0;
    max-width: 36.25rem;
}

///swiper
/// change swiper navigation size
.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 15px;
    font-weight: bold;
}

/// end swiper

///review circle
.cost-rounded-circle {
    background-color: $pink-500;
    border-radius: 50%;
    color: $white;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 9.68rem;
    height: 9.68rem;
}

/// end review circle

/// breakpoints 

@include media-breakpoint-down(xxl) {

    ///review circle
    .cost-rounded-circle {
        width: 8.68rem;
        height: 8.68rem;
    }

}

@include media-breakpoint-down(xl) {

    ///review circle
    .cost-rounded-circle {
        width: 7.68rem;
        height: 7.68rem;
    }

}


@include media-breakpoint-down(lg) {

    // widget
    .widget {
        .widget-header {
            .title {
                font-size: 1.375rem;
                padding: 1.1rem 0;
            }
        }

        .highlight-news {
            .widget-body {
                &__text {
                    margin-top: -2rem;
                    position: relative;
                    width: 24rem;
                    padding-top: 1rem;
                    background-color: white;
                    margin-left: -1px;
                }
            }
        }

        .widget-body {
            &__text {

                p {
                    font-size: 0.875rem;
                    line-height: 1.64;
                }
            }

            &__footer {
                font-size: 0.625rem;
            }
        }

        .reviews {
            padding: 1.5rem 0.5rem;

            &__title {
                font-size: 1.125rem;
            }

        }
    }

    //end of widget
}


@include media-breakpoint-down(md) {

    // widget
    .widget {
        .widget-header {
            .title {
                font-size: 1.125rem;
                padding: 1.1rem 0;
            }
        }

        .highlight-news {
            .widget-body {
                &__text {
                    margin-top: -2rem;
                    position: relative;
                    width: 18rem;
                    padding-top: 1rem;
                    background-color: white;
                }
            }
        }

        .widget-footer {
            padding: 1rem 0;

        }


    }

    //end of widget

    // most-read
    .most-read {
        background-color: $white;

        &__title {
            font-size: 0.875rem;
            padding: 1rem 0;
        }

    }

    // end of most-read

    /// p body line-height 
    .lh-responsive {
        line-height: 1.64;
    }

    // article header
    .article-header {
        &__quote {
            font-size: 1.125rem;
        }

        &__buttons {
            padding-bottom: 1.25rem;
        }

        &__img {
            &--caption {
                width: 3.5rem;
                height: 2.5rem;
                border-radius: 0.25rem;
                left: 0.75rem;
                top: 0.75rem
            }
        }

    }

    // end of article header

    /// Info at a glance part
    .info-part {
        .list-group-flush>.list-group-item {
            padding: 1rem 0;
        }
    }

  ///review circle
  .cost-rounded-circle {
    width: 8rem;
    height: 8rem;
}


}

@include media-breakpoint-only(md) {

    // widget
    .widget {
        .reviews {
            padding: 1.5rem 1rem;

        }
    }
}



//// between sm, md
@include media-breakpoint-between(sm, md) {
    .widget {
        .highlight-news {
            .widget-body {
                &__text {
                    margin-top: -2rem;
                    position: relative;
                    width: calc(100% - 3rem);
                    padding-top: 1rem;
                    background-color: white;
                }
            }
        }

        .reviews {
            padding: 1.5rem;

        }
    }
}